// 
// popover.scss
//

.popover {
    box-shadow: $box-shadow;
}

.popover-header {
    margin-top: 0;
}